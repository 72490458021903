import styled from 'styled-components';
import { Heading2 } from 'components/text';
import Section from 'components/layout/Section';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

interface SocialSectionProps {
  hexCode?: string;
  hasPadding?: boolean;
}

export const SocialSection = styled(Section)`
  background-color: ${(props: SocialSectionProps) =>
    props.hexCode ? props.hexCode : ''};
  margin-bottom: ${(props: SocialSectionProps) =>
    props.hasPadding ? '30px' : 0};

  ${tablet} {
    padding-bottom: 35px;
  }
`;

interface SocialHeading2Props {
  textColor?: Sproutl.ColorScheme;
}

export const SocialHeading2 = styled(Heading2)`
  color: ${(props: SocialHeading2Props) =>
    props.textColor ? theme.colors[props.textColor] : ''};
`;

export const TitleRow = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;

  ${tablet} {
    padding: 0 40px;
  }
`;

export const CuratorLinkA = styled.a`
  color: transparent;
  font-size: 0;
  line-height: 0;
`;

export const Description = styled.p`
  margin: 16px 0 40px;
  color: ${(props: SocialHeading2Props) =>
    props.textColor ? theme.colors[props.textColor] : ''};
`;
