import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

export const Wrapper = styled.div`
  margin-bottom: 80px;
`;

export const TextWrapper = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;
