import React from 'react';
import {
  TitleRow,
  CuratorLinkA,
  SocialSection,
  SocialHeading2,
} from './styles';
import { SocialBlockProps } from './types';
import Container from 'components/layout/Container';
import { PortableText } from 'utils/sanity';

export default function SocialBlock({
  divId,
  title,
  description,
  hasPadding,
  label,
  colorScheme,
}: SocialBlockProps) {
  return (
    <SocialSection hexCode={label?.hexCode} hasPadding={hasPadding}>
      <Container noPadding noGutter>
        {title || description ? (
          <TitleRow>
            {title ? (
              <SocialHeading2 textColor={colorScheme?.text} inline>
                {title}
              </SocialHeading2>
            ) : null}
            <PortableText blocks={description} />
          </TitleRow>
        ) : null}
        <div id={divId}>
          <CuratorLinkA
            href="https://curator.io"
            target="_blank"
            className="crt-logo"
          >
            Powered by Curator.io
          </CuratorLinkA>
        </div>
      </Container>
    </SocialSection>
  );
}
