import styled from 'styled-components';

export const PanelWrapper = styled.div({
  marginTop: 80,
});

export const RichTitleWrapper = styled.div`
  margin-bottom: 60px;
  text-align: center;
`;
